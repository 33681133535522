<template>
  <main class="main">
    <router-view />
    <preview-modal v-if="windowWidth > 425" />
    <register-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
    <verification-modal />
    <app-alerts />
  </main>
</template>

<script>
import { MAIN_RESIZE_DETECTOR } from '../../mixins/utils';
import PreviewModal from '../Shared/modals/PreviewModal.vue';
import RegisterModal from '../Shared/modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../Shared/modals/ConfirmRestoreProfile.vue';
import Alerts from './Alerts.vue';
import VerificationModal from '../Shared/modals/VerificationModal.vue';

export default {
  components: {
    PreviewModal,
    RegisterModal,
    ConfirmRestoreProfile,
    appAlerts: Alerts,
    VerificationModal,
  },
  mixins: [MAIN_RESIZE_DETECTOR],
  props: {
    isGuest: Boolean,
  },
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
  },

  // beforeRouteEnter( to, from, next ) {
  // 	if ( to.name == 'index' ) {
  // 		next( vm => {
  // 			document.body.classList;
  // 			vm.relativeNavbar = false;
  // 		} )
  // 	}
  // 	next()
  // }
};
</script>

<style></style>
