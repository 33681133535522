<template>
  <transition name="modal">
    <div class="vue-modal modal-mask pr-restore" @click="closeModal($event.target, true)">
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container pr-restore--body">
          <div class="modal-header">
            <h4 class="modal-title">Restoring profile</h4>
            <button type="button" class="close" @click="closeModal(false, true)">
              <span class="rbi rbi-close" />
            </button>
          </div>
          <form @submit.prevent="submit">
            <div class="form-group">
              <p>
                Your profile was deleted and now you are trying to restore it. Please, confirm
                license agreement below to continue restoring
              </p>
            </div>
            <div class="form-group" :class="{ 'has-error': err.first('modResTerms') }">
              <checkbox id="preview-checkbox" v-model="rules" name="modResTerms">
                {{ $t('guest.indexHeader.agreement.text') }}
                <router-link :to="{ name: 'terms' }" @click.native="closeModal(false, true)">
                  {{ $t('guest.indexHeader.agreement.link') }}
                </router-link>
              </checkbox>
              <div v-show="err.has('modResTerms')" class="error">
                <i class="fa fa-warning" />
                <span>{{ err.first('modResTerms') }}</span>
              </div>
            </div>
            <rb-button type="submit" :disabled="!active"> Restore my profile </rb-button>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Validator } from 'vee-validate';
import Checkbox from '../parts/CustomCheckbox';
import RbButton from '../../Button';

export default {
  validator: null,
  components: {
    Checkbox,
    RbButton,
  },
  data() {
    return {
      rules: false,
      err: [],
      active: false,
    };
  },
  computed: {
    modal() {
      return this.$store.getters.restoreProfileModal;
    },
    open() {
      return this.modal.open;
    },
  },
  watch: {
    /**
     * Валидация поля "пользовательское соглашение"
     *
     * @param val {string}
     */
    rules(val) {
      const tmp = val ? 1 : '';
      this.validator.validate('modResTerms', tmp).catch(() => {});
      this.active = val;
    },
  },
  created() {
    this.initValidator();
  },
  methods: {
    /**
     * Инициализация плагина валидатора для поля с лицензионным соглашением
     */
    initValidator() {
      this.validator = new Validator();
      this.validator.attach('modResTerms', 'required', {
        prettyName: this.$t('shared.placeholders.license'),
      });
      this.$set(this, 'err', this.validator.errorBag);
    },
    submit() {
      const rules = this.rules ? 1 : '';
      this.validator
        .validateAll({
          modResTerms: rules,
        })
        .then((success) => {
          if (success) {
            this.sendRestoreRequest();
          }
        })
        .catch(() => {});
    },
    sendRestoreRequest() {
      this.$http
        .post('v1/profile/restore-profile', {
          access_token: this.modal.token,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.modal.callback.success();
              this.closeModal(false, false);
            } else {
              if (this.modal.callback.fail) this.modal.callback.fail();
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.requestError'),
              });
            }
          },
          () => {
            if (this.modal.callback.fail) this.modal.callback.fail();
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
            });
          }
        );
    },
    closeModal(e, userAction) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.pr-restore--body').length || !e) {
        if (userAction && this.modal.callback.fail) this.modal.callback.fail();
        this.$store.commit('updateRestoreProfileModal', {
          open: false,
          callback: {
            success: '',
            fail: '',
          },
          token: '',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pr-restore {
  &--header {
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #bababa;
  }
}
.form-group {
  margin-bottom: 15px;
}
.form-group .error {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .pr-restore {
    &--body {
      max-width: 600px;
      width: auto;
    }
  }
}
</style>
