<template>
  <div class="form-wrapper">
    <div class="form">
      <div class="register-title">
        <h3 v-html="registerTitle" />
      </div>
      <form
        class="registration-form"
        data-vv-scope="indHreg"
        @submit.prevent="submitRegistration('indHreg')"
      >
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.sex') }">
          <select
            v-model="registrationForm.sex"
            :data-vv-as="$t('shared.placeholders.sex')"
            name="sex"
            class="form-control"
            :readonly="readonly"
            @focus="unReadonly"
          >
            <option :value="1">I am man seeking for woman</option>
            <option :value="2">I am woman seeking for man</option>
          </select>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.name') }">
          <input
            v-model="registrationForm.name"
            v-validate="'alpha_spaces|required|max:40'"
            type="text"
            :data-vv-as="$t('shared.placeholders.name')"
            name="name"
            class="form-control"
            autocomplete="off"
            :readonly="readonly"
            :placeholder="$t('shared.placeholders.name')"
            @focus="unReadonly"
          />
          <transition name="slide-fade">
            <div v-show="errors.has('indHreg.name')" class="error">
              <span class="is-danger">{{ errors.first('indHreg.name') }}</span>
            </div>
          </transition>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.dob') }">
          <div style="display: flex; justify-content: space-between">
            <select
              v-model="registrationForm.month"
              v-validate="'required'"
              :data-vv-as="$t('shared.placeholders.month')"
              name="month"
              class="form-control"
              style="width: 40%"
              :readonly="readonly"
              @focus="unReadonly"
            >
              <option disabled value>Month</option>
              <option v-for="item in registrationForm.months" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
            <select
              v-model="registrationForm.day"
              v-validate="'required'"
              :data-vv-as="$t('shared.placeholders.day')"
              name="day"
              style="width: 25%"
              class="form-control"
              :readonly="readonly"
              @focus="unReadonly"
            >
              <option disabled value>Day</option>
              <option v-for="item in registrationForm.days" :key="item" :value="item">
                {{ item }}
              </option>
              <option v-if="registrationForm.month !== 'February'" :value="29">29</option>
              <option v-if="registrationForm.month !== 'February'" :value="30">30</option>
              <option v-if="isFullMonth(registrationForm.month)" :value="31">31</option>
            </select>
            <select
              v-model="registrationForm.year"
              v-validate="'required'"
              :data-vv-as="$t('shared.placeholders.year')"
              name="year"
              style="width: 25%"
              class="form-control"
              :readonly="readonly"
              @focus="unReadonly"
            >
              <option disabled value>Year</option>
              <option v-for="item in years" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <transition name="slide-fade">
            <div
              v-show="
                errors.has('indHreg.month') ||
                errors.has('indHreg.day') ||
                errors.has('indHreg.year') ||
                isInvalidDob(registrationForm.day, registrationForm.month, registrationForm.year)
              "
              class="error"
            >
              <span class="is-danger">The date of birth field is required.</span>
            </div>
          </transition>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.email') }">
          <input
            v-model="registrationForm.email"
            v-validate="'required|email|max:50'"
            type="email"
            :data-vv-as="$t('shared.placeholders.email')"
            name="email"
            class="form-control"
            autocomplete="off"
            :readonly="readonly"
            :placeholder="$t('shared.placeholders.email')"
            @focus="unReadonly"
          />
<!--          <transition name="slide-fade">-->
<!--            <div v-show="errEmail" class="error">-->
<!--              <span class="is-danger">Please try a different email address</span>-->
<!--            </div>-->
<!--          </transition>-->
<!--          <transition name="slide-fade">-->
<!--            <div v-show="errEmailTaken" class="error">-->
<!--              <span class="is-danger">Email has already been taken</span>-->
<!--            </div>-->
<!--          </transition>-->
          <transition name="slide-fade">
            <div v-show="errEmail" class="error">
              <span class="is-danger">{{ registrationForm.error }}</span>
            </div>
          </transition>
          <transition name="slide-fade">
            <div v-show="errors.has('indHreg.email')" class="error">
              <span class="is-danger">{{ errors.first('indHreg.email') }}</span>
            </div>
          </transition>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.password') }">
          <input
            v-model="registrationForm.password"
            v-validate="'required|alpha_num|min:8|max:50'"
            type="password"
            :data-vv-as="$t('shared.placeholders.password')"
            name="password"
            class="form-control"
            :readonly="readonly"
            autocomplete="new-password"
            :placeholder="$t('shared.placeholders.password')"
            @focus="unReadonly"
          />
          <transition name="slide-fade">
            <div v-show="errors.has('indHreg.password')" class="error">
              <span class="is-danger">{{ errors.first('indHreg.password') }}</span>
            </div>
          </transition>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('indHreg.terms') }">
          <div class="custom-checkbox">
            <input
              id="cbx"
              v-validate="'required'"
              :data-vv-as="$t('shared.placeholders.license')"
              name="terms"
              class="inp-cbx"
              type="checkbox"
              style="display: none"
            />
            <label class="cbx" for="cbx">
              <span>
                <svg width="12px" height="10px" viewBox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1" />
                </svg>
              </span>
              <span>
                {{ $t('guest.indexHeader.agreement.text') }}
                <router-link :to="{ name: 'terms' }">
                  {{ $t('guest.indexHeader.agreement.link') }}
                </router-link>
              </span>
            </label>
            <transition name="slide-fade">
              <div v-show="errors.has('indHreg.terms')" class="error">
                <span class="help is-danger">{{ errors.first('indHreg.terms') }}</span>
              </div>
            </transition>
          </div>
        </div>
        <rb-button class="btn-outline" :class="{ bdn: entering }">
          <span v-if="entering" :class="{ 'wait-spin': entering }" />
          <span :class="{ wait: entering }">{{ $t('guest.indexHeader.btns.register') }}</span>
        </rb-button>
      </form>
      <!--      <p class="another-variants">or</p>
      <rb-button class="btn-black" type="button" @click.native="FBLoginStatus">
        <span class="rbi-facebook" />
        {{ $t('guest.indexHeader.btns.facebookLogin') }}
      </rb-button>-->
    </div>
    <div class="col">
      <rb-button class="btn-white" @click.native="openRegistrationModal">
        {{ $t('guest.indexHeader.btns.register') }}
      </rb-button>
    </div>
  </div>
</template>

<script>
import { REGISTRATION, REGISTRATION_MODAL, LOGIN } from '../../mixins/formsActions';
import RbButton from '../Button';

export default {
  name: 'RegisterForm',
  components: {
    RbButton,
  },
  mixins: [REGISTRATION, REGISTRATION_MODAL, LOGIN],
  data() {
    return {
      errEmail: false,
      errEmailTaken: false,
      entering: false,
      readonly: true,
      registrationForm: {
        name: '',
        email: '',
        password: '',
        error: '',
        sex: 1,
        day: '',
        month: '',
        year: '',
        days: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
          26, 27, 28,
        ],
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
      },
    };
  },
  computed: {
    registerTitle() {
      const title = this.$t('guest.indexHeader.registration');
      return title.replace(/\b([a-z])([a-z]+)?\b/gim, '<span>$1</span>$2');
    },
  },
  mounted() {

  },
  methods: {
    monthToNum(month) {
      switch (month) {
        case 'January':
          return 1;
        case 'February':
          return 2;
        case 'March':
          return 3;
        case 'April':
          return 4;
        case 'May':
          return 5;
        case 'June':
          return 6;
        case 'July':
          return 7;
        case 'August':
          return 8;
        case 'September':
          return 9;
        case 'October':
          return 10;
        case 'November':
          return 11;
        case 'December':
          return 12;
      }
    },
    isInvalidDob(day, month, year) {
      if (day && month && year) {
        const d = new Date(`${month} ${day}, ${year}`);
        console.log(d);
        const now = new Date();
        // eslint-disable-next-line no-inner-declarations
        function diff_years(dt2, dt1) {
          let diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= 60 * 60 * 24;
          return Math.abs(Math.ceil(diff / 365.26));
        }
        console.log(diff_years(d, now));
        return !(diff_years(d, now) >= 18);
      }
      return false;
    },
    isFullMonth(month) {
      if (
        month === 'January' ||
        month === 'March' ||
        month === 'May' ||
        month === 'July' ||
        month === 'August' ||
        month === 'October' ||
        month === 'December' ||
        month === ''
      ) {
        return true;
      }
      return false;
    },
    unReadonly() {
      this.readonly = false;
    },
    /**
     * Подтвердить регистрацию на сервиса
     *
     * @param scope {string} область видимости для валидатора
     */
    submitRegistration(scope) {
      // const self = this;
      this.entering = true;
      this.registrationForm.error = '';
      this.$validator
        .validateAll(scope)
        .then((success) => {
          if (success) {
            this.mixinRegistration({
              sex: this.registrationForm.sex,
              name: this.registrationForm.name,
              email: this.registrationForm.email,
              password: this.registrationForm.password,
              dob: `${
                this.registrationForm.day < 10
                  ? `0${this.registrationForm.day}`
                  : this.registrationForm.day
              }.${
                this.monthToNum(this.registrationForm.month) < 10
                  ? `0${this.monthToNum(this.registrationForm.month)}`
                  : this.monthToNum(this.registrationForm.month)
              }.${this.registrationForm.year}`,
            }).catch((err) => {
              this.registrationForm.error = err;
              // self.$store.commit('addAlert', {
              //   type: 'error',
                // text: err || 'The Email field must be a valid email.',
                // text: err || 'Some error happened.',
              // });
              this.errEmail = true
              setTimeout(() => {
                this.errEmail = false;
              },3000)
              setTimeout(() => {
                this.entering = false;
              },1000)
              this.registrationForm.error = err;
            });
            // this.entering = false;
          }
          // this.entering = false;
        })
        .catch(() => {
          /* self.$store.commit('addAlert', {
            type: 'error',
            text: 'Some error happened.',
          }) */
          this.entering = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/vars';

.wait-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 20px;
  height: 20px;
  border: 2px solid $black;
  border-left-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}
.bdn {
  border-color: transparent !important;
  &:after {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 15px;
  .btn-white {
    display: none;
  }
}

.form {
  width: 456px;
  background: #ffffff;
  padding: 70px;
  pointer-events: all;
  margin-top: 80px;
  margin-right: 280px;
}

.registration-form {
  .form-group {
    margin-top: 15px;
  }
}

.form-control {
  width: 100%;
}

.has-error {
  .form-control {
    border-bottom-color: $red !important;
  }

  .cbx span:first-child {
    border-color: $red;
  }
}
.custom-checkbox {
  padding-top: 20px;

  label {
    display: flex;
    align-items: center;
    padding: 0 !important;
    color: #000000;
    font-size: $basic-font-size;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 1.6px;

    a {
      color: $red;
    }
  }
}

button {
  width: 100%;
  margin-top: 30px;
}

.btn-black {
  margin-top: 25px;
  span {
    margin-right: 10px;
  }
}

.another-variants {
  font-size: $basic-font-size;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  text-align: center;
  padding: 25px 0 0;
  margin: 0;
}

.register-title {
  h3 {
    color: $black;
    font-size: 34px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 2.5px;
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 86px;
      background: $black;
      position: absolute;
      right: -103px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 1720px) {
  .form {
    width: 406px;
    background: #ffffff;
    padding: 50px;
    pointer-events: all;
    margin-top: 80px;
    margin-right: 0;
  }

  .registration-form {
    .form-group {
      margin-top: 10px;
    }
  }

  .custom-checkbox {
    padding-top: 15px;
  }

  button {
    width: 100%;
    margin-top: 25px;
  }

  .btn-black {
    margin-top: 15px;
  }

  .another-variants {
    font-size: $basic-font-size;
    font-weight: 300;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    text-align: center;
    padding: 15px 0 0;
    margin: 0;
  }
}

@media screen and (max-width: 1260px) {
  .form {
    width: 386px;
    background: #ffffff;
    padding: 40px;
    pointer-events: all;
    margin-top: 80px;
    margin-right: 0;
    font-size: 12px;
  }

  .register-title {
    h3 {
      font-size: 30px;
      line-height: 15px;
    }
  }

  .registration-form {
    .form-group {
      margin-top: 10px;
      .form-control {
        padding: 10px;
      }
    }
  }

  .custom-checkbox {
    padding-top: 15px;
    font-size: 12px;
    label {
      font-size: 12px;
    }
  }

  button {
    width: 100%;
    margin-top: 25px;
  }

  .btn-black {
    margin-top: 15px;
  }

  .another-variants {
    font-size: $basic-font-size;
    font-weight: 300;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    text-align: center;
    padding: 15px 0 0;
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .form {
    display: none;
  }
  .form-wrapper {
    .btn-white {
      display: block;
      pointer-events: all;
      width: 100%;
    }
  }
}
</style>
