<template>
  <section id="header" class="rb-wrapper">
    <div class="slider_wrap">
      <swiper v-show="loaded" ref="mySwiper" :options="opts" class="slider" :auto-update="true">
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="index"
          :style="{ backgroundImage: `url(${slide.srcImage})` }"
        >
          <div class="rb-container container">
            <div class="row">
              <div class="text-slide col col-lg-3">
                <h2 class="title-slide">
                  {{ slide.text.titleText }}
                </h2>
                <p class="description-slide">
                  {{ slide.text.descriptionText }}
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
      </swiper>
      <div class="register-form-slider">
        <div class="rb-container container">
          <div class="row">
            <rb-registration-form />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RbRegistrationForm from '../AuthForms/RegisterForm';

export default {
  name: 'RbMainSlider',
  components: {
    RbRegistrationForm,
  },
  props: {
    showPreloader: Boolean,
  },
  data() {
    return {
      loaded: false,
      mobileImages: true,
    };
  },
  computed: {
    slides() {
      if (window.innerWidth > 1024) {
        return [
          {
            srcImage: './static/images/mainSlider/slide1.webp',
            text: {
              titleText: 'Free Registration',
              descriptionText: 'Connecting singles across the world.',
              anotherHtmlText: '',
            },
          },
          {
            srcImage: './static/images/mainSlider/im5.webp',
            text: {
              titleText: 'Free Registration',
              descriptionText: 'Connecting singles across the world.',
              anotherHtmlText: '',
            },
          },
          {
            srcImage: './static/images/mainSlider/im6.webp',
            text: {
              titleText: 'Free Registration',
              descriptionText: 'Connecting singles across the world.',
              anotherHtmlText: '',
            },
          },
        ];
      } else {
        return [
          {
            srcImage: './static/images/mainSlider/slide1mcrop.webp',
            text: {
              titleText: 'Free Registration',
              descriptionText: 'Connecting singles across the world.',
              anotherHtmlText: '',
            },
          },
        ];
      }
    },
    opts() {
      if (window.innerWidth > 1024) {
        return {
          loop: true,
          effect: 'fade',
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          fadeEffect: {
            crossFade: true,
          },
        };
      } else {
        return {};
      }
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    if (window.innerWidth > 1024) {
      setTimeout(() => {
        this.swiper.update();
        this.loaded = true;
      }, 500);
    } else {
      this.loaded = true;
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/vars';

.slider_wrap {
  position: relative;

  .register-form-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 100%;
    pointer-events: none;

    .container {
      position: relative;
    }
  }
}

.slider {
  width: 100%;
  height: 645px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 15%;
    height: 7px;
    background: $red;
    position: absolute;
    bottom: -7px;
    left: 0;
    z-index: 2;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 70px;
  }
}

.swiper-slide {
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.text-slide {
  width: 30%;
  color: #ffffff;
  letter-spacing: 3px;
  .title-slide {
    font-size: 3.75rem;
    font-weight: 400;
    line-height: 74px;
    border-bottom: 1px solid rgba($white, 0.5);
    padding-bottom: 2.812rem;
  }
  .description-slide {
    padding-top: 2.812rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 26px;
  }
}

@media screen and (max-width: 1720px) {
  .text-slide {
    width: 30%;
    color: #ffffff;
    letter-spacing: 3px;
    .title-slide {
      font-size: 3rem;
      font-weight: 400;
      line-height: 60px;
      border-bottom: 1px solid rgba($white, 0.5);
      padding-bottom: 1.8rem;
    }
    .description-slide {
      padding-top: 1.8rem;
      font-size: 1rem;
      font-weight: 300;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 991px) {
  .text-slide {
    width: 100%;
    text-align: center;
    .title-slide {
      font-size: 2.75rem;
      font-weight: 400;
      line-height: 1;
      border-bottom: 1px solid rgba($white, 0.5);
      padding-bottom: 1rem;
    }
    .description-slide {
      padding-top: 1rem;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1;
    }
  }
}

@media screen and (max-width: 1720px) {
  .slider {
    height: 545px;
  }
}

@media screen and (max-width: 1260px) {
  .slider {
    height: 500px;
  }
}

@media screen and (max-width: 991px) {
  .slider_wrap {
    min-height: 100%;
    .register-form-slider {
      position: absolute;
      top: auto;
      bottom: 80px;
      left: 0;
      width: 100%;
      z-index: 100;
      height: auto;
      pointer-events: none;
    }
  }
  .slider {
    // height: calc(100vh - 74px);
    .swiper-pagination {
      position: absolute;
      bottom: 40px;
    }
  }
}
</style>
