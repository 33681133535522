<template>
  <div class="start-sliders">
    <rb-main-slider class="start-content-wrap-main-slider" />
    <template v-if="pageLoaded">
      <women-slider v-if="topUsers.length" id="topWoman" :users="topUsers" :title="`Top Women`">
        <h3 slot="title"><span>T</span>op Women</h3>
      </women-slider>
      <women-slider v-if="usersOnline.length" id="onlineWoman" :users="usersOnline">
        <h3 slot="title"><span>W</span>omen Online</h3>
      </women-slider>
      <women-slider v-if="lastJoinedUsers.length" id="joinWoman" :users="lastJoinedUsers">
        <h3 slot="title"><span>L</span>ast Joined Women</h3>
      </women-slider>
    </template>
  </div>
</template>

<script>
import RbMainSlider from '../components/RbMainSlider/RbMainSlider.vue';
import WomenSlider from '../components/WomenSlider.vue';

export default {
  name: 'Index',
  slider: null,
  components: {
    RbMainSlider,
    WomenSlider,
  },
  data() {
    return {
      reviews: [
        {
          name: 'Alan',
          age: 44,
          country: 'USA',
          review: `While being on this site, I dreamed of bright future with my woman,
            and I believed that it all could happen to me. So now, before leaving the site, I decided to appeal to everybody.
            It is all possible and real!!! Here i'm found a wonderful woman, and the meeting with her was the
            most pleasant and successful for me. I thank the site for each moment of my happiness.
            There are so many good people here, and I wish everybody to meet their soulmate!`,
        },
        {
          name: 'Tom',
          age: 38,
          country: 'Netherlands',
          review: `Hi there. Actually I had some issues with site.
            But I asked the site for help and they solved all the problems.
            By the way, I’m really amazed with the variety of girls on the
            site. its possible to find the one you want.`,
        },
        {
          name: 'Jack',
          age: 43,
          country: 'Canada',
          review: `WI haven’t been on this site for a long time yet,
            but I’m satisfied with the services it provides. I’ve already
            found girl and I’m going to come to Ukraine soon. Site is simple
            in using and I’m sure there will be no issues for any users.
            Many thanks to administration of the site. `,
        },
        {
          name: 'Max',
          age: 46,
          country: 'USA',
          review: `I tried to find a girl on various sites but this one
            is one of the most convenient me.
            I don’t regret choosing actually this site.
            Girls are amazing, service is pretty good.`,
        },
        {
          name: 'Frank',
          age: 36,
          country: 'Australia',
          review: `It’s not the first site I’ve tried,
            but actually here everything okey.
            I’m using this site for 2 months and I’m pleased.
            Services and variety of girls are really amazing.
             Administrators response all my questions fast.`,
        },
        {
          name: 'Michael',
          age: 42,
          country: 'Ireland',
          review: `While being on this site,
            I dreamed of bright future with my woman,
            and I believed that it all could happen to me. So now, before leaving the site,
            I decided to appeal to everybody. It is all possible and real!!! You see my photo with …..,
            she is a wonderful woman, and the meeting with her was the most pleasant and successful for me.
            I thank the site for each moment of my happiness. There are so many good people here,
            and I wish everybody to meet their soulmate!`,
        },
        {
          name: 'Bill',
          age: 46,
          country: 'USA',
          review: `I never believed in success on dating sites in the Internet.
            I could never understand how to get close to a person when he is so far away from you.
            I decided to try it, because I understood that in order to get something, you need to act ,
            but not sit and wait. I had a faith and I was patient. I was searching and searching and finally
            I met her. As it turned out, despite the fact that we are so far away, we have a lot in common.
            Thanks to the site for this chance to become closer. Now we are going to meet and I'm sure that the
            ${this.siteName} will help us in it. You need to try also. I'm sure you will find the right person for you.`,
        },
        {
          name: 'Steve',
          age: 42,
          country: 'United Kingdom',
          review: `Her name is Elena. And we are very happy together.
            She gave me sip of fresh air when I first met her on this site.
            She showed me the real values in the relationship and proved
            that if we want to change our life we have to use all sorts
            of ways. This site helped me to find a lady who opened up
            the meaning of life for me again. Now we are preparing for
            the wedding and sincerely grateful to ${this.siteName} for this
            chance to be together`,
        },
        {
          name: 'Eric',
          age: 47,
          country: 'USA',
          review: `In everyday life with are so busy with our duties
            that we do not notice how life flies in loneliness.
            Dating site opened up for me the opportunity to  embellish
            my busy life. With the help of ${this.siteName} I found a lady with
            who we became wonderful friends. She is so good in communication ,
            I feel so nice together with her. My gray working days are much
            more pleasant now, because we are always together online.
            We plan to meet in near future and I am sure it will be
            unforgettable for us both. We are thankful to this site for
            real good services.`,
        },
      ],
      topUsers: [],
      lastJoinedUsers: [],
      usersOnline: [],
      count: 0,
      showPreloader: true,
      topUsersLoad: false,
      pageLoaded: false,
      windowLoaded: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    /**
     * При загрузке компонента скроллим в самый верх обновляем состояния.
     * При отгрузке компонента тоже скроллим вверх
     *
     */
    setTimeout(() => {
      this.windowLoaded = true;
    }, 500);
    this.toggleState();

    window.addEventListener('scroll', this.setPageLoadedToTrue);

    window.scrollTo(0, 0);
  },
  methods: {
    /**
     * Обновляет состояния компонента после загрузки страницы
     */
    setPageLoadedToTrue() {
      this.pageLoaded = true;
      /**
       * Получить список ТОП девушек
       */
      this.$http.post('v1/topwoman', { limit: 12 }).then(
        (responseFirst) => {
          this.topUsersLoad = false;
          responseFirst.json().then(
            (response) => {
              if (response.status) {
                this.topUsersLoad = true;
                this.topUsers = response.result;
              }
            },
            (error) => {
              this.topUsersLoad = false;
              console.log(error);
            }
          );
        },
        (error) => {
          this.topUsersLoad = false;
          console.log(error);
        }
      );

      /**
       * Получить список последних присоединившихся девушек
       */
      this.$http.post('v1/user-guest/last-joined-us-woman', { limit: 12 }).then(
        (responseFirst) => {
          responseFirst.json().then(
            ({ status, result }) => {
              // eslint-disable-next-line no-unused-expressions
              if (status) this.lastJoinedUsers = result;
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );

      /**
       * Получить список девушек онлайн
       */
      this.$http.post('v1/user-guest/online-woman', { limit: 12 }).then((response) => {
        response.json().then(({ status, result }) => {
          if (status) {
            this.usersOnline = result.list;
            this.count = +result.count;
          }
        });
      });
      //window.removeEventListener('touchmove', this.setPageLoadedToTrue)
      window.removeEventListener('scroll', this.setPageLoadedToTrue);
    },
    toggleState() {
      // eslint-disable-next-line no-undef
      hideLoader();
      //window.addEventListener('touchmove', this.setPageLoadedToTrue)

      this.showPreloader = false;
    },
  },

  /**
   * Перед входом на страницу, если есть запись в localstorage - перекинуть на страницу профиля.
   * В противном случае загружаем страницу и обновляем прелоадер
   *
   * @param to
   * @param from
   * @param next
   */
  beforeRouteEnter(to, from, next) {
    if (window.localStorage['access-token']) {
      next((vm) => {
        vm.$router.replace({
          name: 'profile',
          params: {
            id: vm.user.user_id,
          },
        });
        if (from.name === 'index') {
          vm.$router.replace({
            name: 'profile',
            params: {
              id: vm.user.user_id,
            },
          });
        }
      });
    }
    if (from.name !== 'index') {
      next((vm) => {
        vm.showPreloader = false;
        vm.showPreloader = true;
        vm.showPreloader = false;
      });
    }
    document.title = `Welcome! | ${this.siteName}`;
    next();
  },

  /**
   *
   * Очищает функцию onbeforeunload при покидании страницы
   */
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>

<style lang="scss" scoped>
.start-sliders {
  min-height: 1000px;
  @media screen and (max-width: 1024px) {
    min-height: 900px;
  }
}
</style>
