<template>
  <section class="rb-wrapper">
    <div class="rb-container container">
      <div class="section-title">
        <slot name="title" />
        <div v-if="opt.navigation" class="slider-nav">
          <button class="nav-btn prev-btn" :class="[opt.navigation.prevEl.replace('.', '')]" />
          <button class="nav-btn next-btn" :class="[opt.navigation.nextEl.replace('.', '')]" />
        </div>
      </div>
    </div>
    <div v-if="opt.navigation" class="relative_block">
      <swiper :options="opt">
        <swiper-slide v-for="user in users" :key="user.user_id">
          <user-preview :user="user" />
        </swiper-slide>
      </swiper>

      <!--<swiper :options="opt" class="users-previews" >-->
      <!--<swiper-slide v-for="user in users" :key="user.user_id">-->
      <!--<user-preview :user="user"/>-->
      <!--</swiper-slide>-->
      <!--</swiper>-->
    </div>
  </section>
</template>

<script>
import UserPreview from './Shared/UserPreview';

export default {
  nextEl: null,
  name: 'UsersSlider',
  components: {
    UserPreview,
  },
  props: {
    showPreloader: Boolean,
    users: Array,
  },
  data: () => {
    return {
      opt: {
        slidesPerView: 3,
        breakpoints: {
          // when window width is <= 320px
          991: {
            slidesPerView: 5,
          },
          767: {
            slidesPerView: 3,
          },
        },
      },
    };
  },

  created() {
    Object.assign(this.opt, {
      navigation: {
        prevEl: `.prev-btn-${this._uid}`,
        nextEl: `.next-btn-${this._uid}`,
      },
    });
  },
};
</script>

<style scoped lang="scss">
.users-previews {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-nav {
  .nav-btn {
    width: 40px;
    height: 25px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    margin-right: 20px;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .next-btn {
    background-image: url('/static/images/svg/icons/right-arrow.svg');
  }

  .prev-btn {
    background-image: url('/static/images/svg/icons/left-arrow.svg');
  }

  .swiper-button-disabled {
    opacity: 0.2;

    &:hover {
      opacity: 0.2;
    }
  }
}

@media screen and (max-width: 1470px) {
  .section-title {
    margin: 0 0 45px;

    h3 {
      font-size: 40px;
      line-height: 36px;

      &:after {
        height: 2px;
        top: calc(50% + 2px);
      }
    }
  }
  .slider-nav {
    .nav-btn {
      width: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .section-title {
    margin: 0 0 25px;

    h3 {
      font-size: 35px;
      line-height: 0.74;

      &:after {
        height: 2px;
        top: calc(50% + 2px);
      }
    }
  }
  .slider-nav {
    .nav-btn {
      width: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .section-title {
    h3 {
      font-size: 25px;
      line-height: 0.74;

      &:after {
        height: 2px;
        width: 40px;
        right: -50px;
        top: calc(50% + 2px);
      }
    }
  }
  .slider-nav {
    display: none;

    .nav-btn {
      width: 30px;
    }
  }
}
</style>
