<template>
  <div class="rb-page">
    <div class="container">
      <div class="white-box">
        <div class="d-flex align-items-center justify-content-center">
          <div class="reset-pass">
            <div class="text-center">
              {{ $t('forgotPswd.tip') }}
            </div>
            <div class="form-wrap">
              <form data-v-scope @submit.prevent="sendResetPass">
                <div
                  class="item input-wrap form-group"
                  :class="{ 'has-error': errors.first('fPas') }"
                >
                  <input
                    v-model="newPass"
                    v-validate="'required|alpha_num|min:8'"
                    :placeholder="$t('forgotPswd.pswd')"
                    :data-vv-as="$t('forgotPswd.pswd')"
                    type="password"
                    name="fPas"
                    class="form-control"
                  />
                  <div v-show="errors.has('fPas')" class="error">
                    <i class="fa fa-warning is-danger" />
                    <span class="is-danger">{{ errors.first('fPas') }}</span>
                  </div>
                </div>
                <div
                  class="item input-wrap form-group"
                  :class="{ 'has-error': errors.first('lPas') }"
                >
                  <input
                    v-model="repeatPass"
                    v-validate="'required|alpha_num|min:8|confirmed:fPas'"
                    :placeholder="$t('forgotPswd.repPswd')"
                    type="password"
                    :data-vv-as="$t('forgotPswd.pswd')"
                    name="lPas"
                    class="form-control"
                    @change="validatePass"
                  />
                  <div v-show="errors.has('lPas')" class="error">
                    <i class="fa fa-warning is-danger" />
                    <span class="is-danger">{{ errors.first('lPas') }}</span>
                  </div>
                </div>
                <div class="item btn-wrap">
                  <rb-button class="btn btn-red">
                    {{ $t('forgotPswd.submit') }}
                  </rb-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import RbButton from '../Button';

export default {
  components: {
    RbButton,
  },
  props: {
    customStyles: Boolean,
  },
  data() {
    return {
      newPass: '',
      repeatPass: '',
      passwordToken: '',
      isError: false,
    };
  },
  computed: {},
  watch: {
    /**
     * инициализация валидации пароля
     *
     * @param val {string}
     */
    repeatPass() {
      this.validatePass();
    },
    /**
     * инициализация валидации пароля
     *
     * @param val {string}
     */
    newPass() {
      this.validatePass();
    },
  },

  mounted() {
    this.getToken();
    document.title = this.$t('forgotPswd.title');
  },
  methods: {
    /**
     * получить токен восстановления пароля из URLа
     */
    getToken() {
      this.passwordToken = this.$route.query.token;
    },
    /**
     * валидация паролей на совпадение
     */
    validatePass() {
      if (this.newPass !== this.repeatPass) {
        this.isError = true;
      } else {
        this.isError = false;
      }
    },
    /**
     * валидация формы на восстановление пароля и дальнейшая отправка данных на сервер
     */
    sendResetPass() {
      this.$validator
        .validateAll()
        .then((success) => {
          if (success) {
            this.$http
              .post('v1/user/password-reset', {
                password_reset_token: this.passwordToken,
                password: this.newPass,
              })
              .then(
                (response) => {
                  response.json().then(
                    (response) => {
                      if (response.status) {
                        localStorage.removeItem('access-token');
                        this.$router.push({
                          name: 'index',
                        });
                        this.$store.commit('addAlert', {
                          type: 'success',
                          text: 'Password successfully changed',
                          info: 'change pass',
                        });
                      }
                      if (!response.status) {
                        this.$store.commit('addAlert', {
                          type: 'error',
                          text: this.$t('alerts.someErr'),
                          info: 'change pass',
                        });
                      }
                    },
                    (error) => {
                      this.$store.commit('addAlert', {
                        type: 'error',
                        text: this.$t('alerts.someErr'),
                        info: error,
                      });
                    }
                  );
                },
                (error) => {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                    info: error,
                  });
                }
              );
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-pass-wrap {
  background: url(/static/images/slider_01.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;

  .index-navbar,
  .user-navbar {
    position: relative;
    &:before {
      //background: none !important;
      display: none;
    }
  }

  .relative {
    position: relative;
    top: 0;
  }

  .white-box {
    padding: 30px 0;
    align-items: center;
    justify-content: center;
  }
}

.reset-pass {
  width: 100%;
  max-width: 420px;
  input {
    width: 100%;
  }
  .text-center {
    font-size: 18px;
    color: #3e3f42;
    margin-bottom: 55px;
    font-weight: 700;
  }

  i + p {
    margin-left: 5px;
  }

  .input-wrap + .input-wrap {
    margin-top: 18px;
  }
  .btn-wrap {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
  }
  .btn-submit {
    width: 80px;
    margin: auto;
    height: 36px;
    color: white;
    background: #e21919;
  }
}

.feedback-wrap {
  margin-bottom: 20px;
}

.error {
  color: red;
  display: inline-flex;
  padding-top: 10px;
}
</style>
